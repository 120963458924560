const faqs = [
  {
    question: "Documentación vigente:",
    answer: (
      <div>
        <p>Si deseas entrar a territorio español deberás hacerlo por los puestos habilitados al efecto, tener en posesión tu pasaporte o documento de viaje que valide tu identidad con validez mínima de 3 meses a partir del inicio del viaje.</p>
      </div>
    ),
  },
  {
    question: "Pasajes de avión de ida y vuelta:",
    answer: (
      <div>
        <p>
        Para una estadía legal de hasta 90 días, para entrar a España debes poseer un pasaje de avión ida y vuelta o de continuación, dependiendo de cual sea tu caso. 
        </p>
      </div>
    ),
  },
  {
    question: "Solvencia económica:",
    answer: (
      <div>
        <p>
        Comprobar medios económicos suficientes para la permanencia en España y el retorno o traslado a otro país. El tiempo de estancia puede variar dependiendo la cantidad de recursos económicos, mediante diligencia en el pasaporte o documento análogo). 
        </p>
      
      </div>
    ),
  },
  {
    question: "Justificación de entrada al país:",
    answer: (
      <div>
        <p>
        Si así se requiere, debes especificar el motivo de tu solicitud de entrada y estancia en España. Los funcionarios de migraciones responsables del control de entrada podrán exigirles la presentación de documentos que justifiquen o establezcan la credibilidad de la razón de entrada pretendida.  Incluye: Invitación de empresas para actividades comerciales o industriales, entradas a ferias, reservas o programas de eventos.
        </p>
      
      </div>
    ),
  },
  {
    question: "Presentación de los certificados sanitarios:",
    answer: (
      <div>
        <p>
        De acuerdo con el Ministerio de Sanidad de España, los turistas que deseen ingresar deberán cumplir con alguno de los siguientes requisitos:
        </p> <br/>
        <p>
        - Certificado de vacunación completo con vacunas aprobadas por la Agencia Europea de Medicamentos (EMA) o la Organización Mundial de la Salud (OMS).        </p>
        <p>
        - Test de antígenos negativo (aplicado máximo 24 horas previas al viaje).
        </p>
        <p>
        - Prueba NAAT negativa (aplicada máximo 72 horas previas al viaje).
        </p>
        <p>
- Certificado de recuperación emitido por las autoridades correspondientes. Tomado al menos 11 días posteriores a la aplicación del primer test de antígenos o prueba NAAT con resultados positivos.
        </p>
      
      </div>
    ),
  },
  {
    question: "Carta de invitación:",
    answer: (
      <div>
        <p>
        Debes presentar una carta de invitación, reservas de hotel o constancias de alojamiento. 
        </p>
      
      </div>
    ),
  },
  
];

export default faqs;
